import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Loader } from '../components';
import CartList from '../components/common/desktop-navigation-bar/Cart-List/CartList';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { useGetEvent } from '../hooks/useGetEvent';
import VoucherSection from '../components/profile/Profile-Vouchers/VoucherSection';
import CardSection from '../components/profile/Profile-Wallet/CardSection';
import { palettes } from '../config';
import { DELETE_CART } from '../graphql/cart/mutation';
import { GET_CART } from '../graphql/cart/queries';
import { GET_APP_CONFIG } from '../graphql/config/queries';
import { CREATE_ORDER, SET_PAYMENT_STATUS } from '../graphql/order/mutations';
import {
	ATTACH_CARD_TO_USER,
	CHARGE_CUSTOMER,
	CONFIRM_CHARGE,
} from '../graphql/stripe/mutations';
import { LIST_CARDS } from '../graphql/stripe/queries';
import { UPDATE_VOUCHER } from '../graphql/vouchers/mutation';
import { GET_ALL_VOUCHERs } from '../graphql/vouchers/queries';
import { useOnCompleteOrderWithCard } from '../hooks/handleMoneyDistribution/useCardPaymentComplete';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { useOneStopCardPayment } from '../hooks/useOneStopCardPayment';
import { useSetAlert } from '../hooks/useSetAlerts';
import {
	setDefaultCard,
	setloadingCards,
	setUserCards,
} from '../redux/getCardsSlice';
import { setVoucherLoading } from '../redux/loaderSlice';
import { setCreatedCardId } from '../redux/oneStopCheckoutSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setWasCardUsed } from '../redux/userSlice';
import { setVouchers } from '../redux/walletSlice';
import { TUseNavigation } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { monthsByNumber } from '../utils/monthsUtils';
import { getCountryLabelFromValue } from '../utils/getCountryLabelFromValue';
import { loadStripe } from '@stripe/stripe-js';
import { stripe_key } from '../utils/stripeUtil';
import { useTranslation } from 'react-i18next';
import { setaffiliatedBrands } from '../redux/createEventSlice';
import { useGetCart } from '../hooks/useGetCart';
import { setCurrentScreen } from '../redux/uiSlice';
import useCreateAnonymousUser from '../api/auth/useCreatAnonymousUser';
import { setVisitorCart } from '../redux/userSlice';

const CheckoutScreen = () => {
	const dispatch = useAppDispatch();
	const { shouldHideScreen } = useHandleUnmount();
	const [loadingStripe3Ds, setLoadingStripe3Ds] = useState(false);
	const { loggedInUserDetails } = useAppSelector(state => state.auth);
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(setCurrentScreen('/checkout'));
	}, []);

	const [itemsInCartNotAvailable, setItemsInCartNotAvailable] = useState<
		string[]
	>([]);
	const cards = useAppSelector(state => state.userCards.UserCards);
	const { selectedVoucher } = useAppSelector(state => state.wallet);
	const eventBoughtFrom = useAppSelector(state => state.user.buyingFromEvent);

	const [cardSelected, setCardSelected] = useState('');
	useEffect(() => {
		for (let i = 0; i < cards.length; i++) {
			if (cards[i].default) {
				dispatch(setDefaultCard(cards[i]));
				break;
			}
		}
	}, [cards]);

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { setAlert } = useSetAlert();

	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);
	const userAccountType = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const [voucherPaymentSuccessful, setVoucherPaymentSuccessful] =
		useState<boolean>(false);

	const [deliveryFee, setDeliveryFee] = useState(0);
	const [maxOrderForDeliveryFee, setMaxOrderForDeliveryFree] = useState(0);
	const { loading: CardsLoading, data: cardsListed } = useQuery(LIST_CARDS, {
		variables: {
			args: {
				id: stripeId_,
			},
		},
		onCompleted: res => {
			dispatch(setUserCards(res.listCards.data.data));
		},
	});
	const [chargeCustomer, { data: chargingData, loading: chargingLoading }] =
		useMutation(CHARGE_CUSTOMER, {
			errorPolicy: 'all',
			onError: () => {
				setAlert('Unable to Complete Purchase', 'danger');
			},
		});

	const [deleteCart, { loading: deleteCartLoading }] = useMutation(
		DELETE_CART,
		{
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_CART }],
		}
	);

	const { getCart, gettingCartLoading } = useGetCart();

	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	let Total = 0;
	usersCart.map(product => {
		Total +=
			product.type === 'Product'
				? product?.price * product.quantity
				: product.starterkitId.price * product.quantity;
	});
	const [amountToBePaid, setAmountToBePaid] = useState<number>(Total);
	const [selectedVoucherAmountLeft, setSelectedVoucherAmountLeft] = useState(
		Number(selectedVoucher?.amount)
	);
	const { data: vouchersData, loading: gettingVoucher } = useQuery(
		GET_ALL_VOUCHERs,
		{
			onCompleted: () => {
				dispatch(setVouchers(vouchersData?.getAllVouchers?.data.data));
				dispatch(setVoucherLoading(false));
			},
		}
	);
	let brandsInCart: any = {};
	let brandInCartIds: string[] = [];
	usersCart.map(product => {
		if (product.type === 'Product') {
			if (
				Object.keys(brandsInCart).includes(
					product.productId?.associatedProduct?.brand?._id
				)
			) {
				brandsInCart[
					product.productId?.associatedProduct?.brand
						?._id as keyof typeof brandsInCart
				] += 1;
				// brandCounts += 1
			} else {
				brandsInCart[
					product.productId?.associatedProduct?.brand
						?._id as keyof typeof brandsInCart
				] = 1;
				brandInCartIds.push(
					product.productId?.associatedProduct?.brand?._id
				);
			}
		} else {
			if (
				Object.keys(brandsInCart).includes(
					product.starterkitId.brand._id
				)
			) {
				brandsInCart[
					product.starterkitId.brand._id as keyof typeof brandsInCart
				] += 1;
				// brandCounts += 1
			} else {
				brandsInCart[
					product.starterkitId.brand._id as keyof typeof brandsInCart
				] = 1;
				brandInCartIds.push(product.starterkitId.brand._id);
			}
		}
	});

	const isAnyItemInCartNotAvailable = async () => {
		const itemsNotAvailable: string[] = [];
		setItemsInCartNotAvailable([]);
		const resss = await getCart();
		// await getCart().then((resss) => {
		const cart_: Cart = resss?.data?.getCart?.data?.data?.map(
			item => item.items
		);
		cart_?.map(product => {
			if (
				product?.productId &&
				Number(product?.productId?.option?.inventory?.quantity) <
				product.quantity
			) {
				setItemsInCartNotAvailable(prev => [
					...prev,
					product?.productId?.associatedProduct?.name,
				]);
				itemsNotAvailable.push(
					product?.productId?.associatedProduct?.name
				);
			}
			if (
				product?.starterkitId &&
				product?.starterkitId?.inventory &&
				Number(product?.starterkitId?.inventory?.quantity) <
				product.quantity
			) {
				setItemsInCartNotAvailable(prev => [
					...prev,
					product?.starterkitId?.name,
				]);
				itemsNotAvailable.push(product?.starterkitId?.name);
			}
		});
		// })
		if (itemsNotAvailable.length > 0) {
			return itemsNotAvailable;
		}
		return false;
	};
	const chargeAmount = (amount: number) => {
		// amount is * 100 because the currency is in the smallest unit, i.e for eur, it's cent, for euros, it's also cent
		const totalAmountToBeCharged = Math.round(amount * 100);

		return totalAmountToBeCharged;
	};

	// CREATING ORDER //
	const [
		createOrder,
		{ loading: createOrderLoading, error: createOrderError },
	] = useMutation(CREATE_ORDER);
	useEffect(() => {
		if (createOrderError) {
			setAlert(`${t('Unable to complete order')}`, 'danger');
		}
	}, [createOrderError]);
	// PAYMENT //

	let items: Items[] = [];
	usersCart.map(cartItem => {
		if (cartItem.type === 'StarterKit') {
			items.push({
				itemType: 'STARTER_KIT',
				variant: null,
				quantity: cartItem?.quantity,
				price: cartItem?.starterkitId?.price,
				brand: cartItem?.starterkitId?.brand?._id,
				brandConnectId:
					cartItem?.starterkitId?.brand?.connectAccountInfo
						?.connectAccountId,
				starterKit: cartItem?.starterkitId?._id,
				vat: cartItem?.starterkitId?.vat,
				brandName: cartItem?.starterkitId?.brand?.name,
				starterKitName: cartItem?.starterkitId?.name,
			});
		} else {
			items.push({
				productName: cartItem?.productId?.associatedProduct?.name,
				brandName: cartItem?.productId?.associatedProduct?.brand?.name,
				itemType: 'PRODUCT',
				variant: cartItem?.productId?._id,
				starterKit: null,
				quantity: cartItem?.quantity,
				affiliatedHost: cartItem?.eventId?.affiliatedHosts[0]?._id,
				selfHosted: !cartItem?.eventId?.isHostRequired,
				affiliatedSellerConnectId:
					cartItem?.eventId?.affiliatedSeller?.connectAccountInfo
						?.connectAccountId,
				price: cartItem.price,
				brand: cartItem.productId.associatedProduct?.brand?._id,
				brandConnectId:
					cartItem?.productId?.associatedProduct?.brand
						?.connectAccountInfo?.connectAccountId,
				affiliatedSellerId: cartItem.eventId?.affiliatedSeller?._id,
				vat: cartItem?.productId?.vat,
				eventName: cartItem?.eventId?.name,
				optionValue: cartItem.optionValue,
				variantType: cartItem?.variantType,
				associatedVariantType: cartItem?.associatedVariantType
			});
		}
	});

	const [attachPaymentToCustomer] = useMutation(ATTACH_CARD_TO_USER, {
		refetchQueries: [
			{
				query: LIST_CARDS,
				variables: {
					args: {
						id: stripeId_,
					},
				},
			},
		],
	});

	const onCompleteOrderWithCard = useOnCompleteOrderWithCard();

	useEffect(() => {
		setCardSelected('');
	}, []);

	const { isSaveCard } = useAppSelector(state => state.userCards);

	const isShowCardSection = selectedVoucher.amount
		? +selectedVoucher.amount < amountToBePaid || amountToBePaid > 0
			? true
			: false
		: true;

	const [setPaymentStatus, { loading: setPaymentStatusLoading }] =
		useMutation(SET_PAYMENT_STATUS);

	const onChargingCustomerSuccess = (
		data: TODO,
		items: [],
		orderId: string
	) => {
		if (
			(data &&
				data.chargeCustomer?.success === true &&
				data.chargeCustomer?.statusCode === 200) ||
			(data &&
				data.chargeCustomerWithoutSavingCard?.success === true &&
				data.chargeCustomerWithoutSavingCard?.statusCode === 200) ||
			(data &&
				data.confirmCharge?.success === true &&
				data.confirmCharge?.statusCode === 200) ||
			!isShowCardSection
		) {
			const chargeId_ =
				data?.chargeCustomer?.data?.charges?.data[0]?.id ??
				data?.chargeCustomerWithoutSavingCard?.data?.charges?.data[0]
					?.id ??
				data?.confirmCharge?.data?.charges?.data[0]?.id;

			if (selectedVoucher) {
				const amountLeft =
					+selectedVoucher.amount <= amountToBePaid
						? 0
						: +selectedVoucher.amount - (Total + deliveryFee);
				const calculatedAmountLeft = amountLeft < 0 ? 0 : amountLeft;
				const amountSpent =
					+selectedVoucher.amount <= amountToBePaid
						? +selectedVoucher.amount
						: +selectedVoucher.amount - (Total + deliveryFee);
				const voucherUpdateInfo = [
					{
						_id: selectedVoucher.voucherId,
						amountLeft: calculatedAmountLeft,
						amountSpent,
					},
				];
				updateVoucher({
					variables: {
						args: { voucherUpdateInfo },
					},
				});
			}
			if (data.chargeCustomerWithoutSavingCard?.success) {
				dispatch(
					setCreatedCardId(
						data?.chargeCustomerWithoutSavingCard?.data
							?.payment_method
					)
				);

				if (isSaveCard) {
					attachPaymentToCustomer({
						variables: {
							args: {
								id: data?.chargeCustomerWithoutSavingCard?.data
									?.payment_method,
								customerId: stripeId_,
							},
						},
					});
				}
			}

			if (isShowCardSection) {
				onCompleteOrderWithCard({
					chargeId: chargeId_,
					items: items,
					deliveryFee: deliveryFee,
					amountToBePaid: amountToBePaid,
				});
			}

			// createOrder({
			// 	variables: {
			// 		args: {
			// 			items,
			// 			paidWith: 'CARD',
			// 			brand:
			// 				brandCount > 1
			// 					? 'Multiple'
			// 					: brandCount === 1 && brandInCartIds[0],
			// 			eventType: 'LIVE',
			// 			purchasedTotalAmount: Total,
			// 			deliveryFee,
			// 			deliveryAddress: {
			// 				address: payingAddressDeets.address,
			// 				city: payingAddressDeets.city,
			// 				zipCode: payingAddressDeets.zipCode,
			// 				country: getCountryLabelFromValue(
			// 					payingAddressDeets.countery
			// 				),
			// 			},
			// 		},
			// 	},
			// 	onCompleted: data => {
			// 		if (isShowCardSection) {
			// 			onCompleteOrderWithCard({
			// 				chargeId: chargeId_,
			// 				items: data?.createOrder?.data?.items,
			// 				deliveryFee: deliveryFee,
			// 				amountToBePaid: amountToBePaid,
			// 			});
			// 		}
			// 	},
			// });
			deleteCart();
			dispatch(setVisitorCart([]));
			dispatch(setWasCardUsed(true));
		} else {
			setPaymentStatus({
				variables: {
					orderId,
					paymentStatus: 'FAILED',
				},
			});
			setAlert(t('Unable to complete payment'), 'danger');
		}
	};

	const [
		confirmPayment,
		{ data: confirmChargeData, loading: confirmChargeLoading },
	] = useMutation(CONFIRM_CHARGE);

	const {
		chargeCustomerWithoutSavingCardFn,
		chargingWithoutCardLoading,
		chargingWithoutCardData,
	} = useOneStopCardPayment({
		onChargingCustomerSuccess,
		confirmPayment,
		setLoadingStripe3Ds,
	});

	// CHARGING CUSTOMER
	const chargingCustomer = (
		cardSelected: string,
		data: any,
		orderId: string,
		items: [],
		anonymousUserId?: string
	) => {
		if (cardSelected) {
			chargeCustomer({
				variables: {
					args: {
						amount: chargeAmount(amountToBePaid),
						currency: 'eur',
						cardId: cardSelected,
						customerId: stripeId_,
						orderId: orderId,
						associatedUser: isUserLoggedIn
							? loggedInUserDetails?._id
							: null,
						associatedAnonymousUser: !isUserLoggedIn
							? anonymousUserId
							: null,
					},
				},
				onCompleted: async data => {
					if (
						data?.chargeCustomer?.message === 'Charge not completed'
					) {
						setLoadingStripe3Ds(true);
						const stripe = await loadStripe(stripe_key);
						await stripe?.handleCardAction(
							data?.chargeCustomer?.data?.client_secret
						);
						setLoadingStripe3Ds(false);
						confirmPayment({
							variables: {
								args: {
									paymentIntentKey:
										data?.chargeCustomer?.data?.id,
								},
							},
							onCompleted: async res => {
								if (res?.confirmCharge?.success) {
									await onChargingCustomerSuccess(
										res,
										items,
										orderId
									);
								} else {
									setAlert(
										'Unable to complete payment',
										'danger'
									);
									await setPaymentStatus({
										variables: {
											orderId,
											paymentStatus: 'FAILED',
										},
									});
								}
							},
						});
					} else if (
						data?.chargeCustomer?.message === 'Charge succeeded'
					) {
						await onChargingCustomerSuccess(data, items, orderId);
					} else {
						setAlert('Unable to complete payment', 'danger');
					}
				},
				onError: async () => {
					await setPaymentStatus({
						variables: {
							orderId,
							paymentStatus: 'FAILED',
						},
					});
				},
			});
		} else {
			chargeCustomerWithoutSavingCardFn({
				args: {
					amount: chargeAmount(amountToBePaid),
					currency: 'eur',
					orderId,
					associatedUser: isUserLoggedIn
						? loggedInUserDetails?._id
						: null,
					associatedAnonymousUser: !isUserLoggedIn
						? anonymousUserId
						: null,
					cardArgs: {
						number: payingCardDeets.cardNo || data?.cardNo,
						exp_month:
							monthsByNumber[
							(payingCardDeets.expMonth as keyof typeof monthsByNumber) ||
							data?.expMonth
							],
						exp_year:
							Number(payingCardDeets.expYear) ||
							Number(data?.expYear),
						cvc: payingCardDeets.cvv || data?.cvv,
					},
					userDetailsArgs: {
						name: payingCardDeets.name || data?.name,
					},
				},
				orderId,
				items,
			});
		}
		// }
	};
	const [payNowData, setPayNowData] = useState();
	const { createAnonymousUser } = useCreateAnonymousUser({
		onCompleted: data => {
			payNowCard(null, payNowData, data?.createAnonymousUser?.data?._id);
		},
	});
	const handleCreateAnonymousUser = (
		cardSelected?: string,
		data?: any,
		anonymousUserId?: string
	) => {
		createAnonymousUser({
			anonymousUserData: {
				email: payingAddressDeets.email,
				isCreateStripeUser: true,
				personalInformation: {
					firstName: payingAddressDeets.firstName,
					lastName: payingAddressDeets.lastName,
					phoneNumber: payingAddressDeets.phoneNumber,
				},
			},
		});
		setPayNowData(data);
	};

	// ----  PAYING WITH A SAVED CARD ONLY  ------
	const payNowCard = async (
		cardSelected?: string,
		data?: any,
		anonymousUserId?: string
	) => {
		const res = await isAnyItemInCartNotAvailable();
		if (res) {
			setAlert(
				t('Items') +
				' : ' +
				res.toString() +
				' ' +
				t('are no longer available'),
				'danger'
			);
		} else {
			createOrder({
				variables: {
					args: {
						items,
						associatedUser: isUserLoggedIn
							? loggedInUserDetails?._id
							: null,
						associatedAnonymousUser: !isUserLoggedIn
							? anonymousUserId
							: null,
						paidWith: 'CARD',
						brands: brandInCartIds,
						eventType: 'LIVE',
						orderPaymentStatus: 'CHECKOUT',
						purchasedTotalAmount: Total,
						deliveryFee,
						deliveryInfo: {
							name: payingAddressDeets?.name,
							phoneNumber: payingAddressDeets?.phoneNumber,
							emailAddress: isUserLoggedIn
								? loggedInUserDetails?.email
								: payingAddressDeets.email,
							deliveryAddress: {
								address: payingAddressDeets.address,
								city: payingAddressDeets.city,
								zipCode: payingAddressDeets.zipCode,
								country: getCountryLabelFromValue(
									payingAddressDeets.countery
								),
							},
						},
					},
				},
				onCompleted: async res => {
					// await setOrderItems(res?.createOrder?.data?.items)
					console.log({ orderInfo: res, brandInCartIds });
					const orderId = res?.createOrder?.data?.orderId;
					const items = res?.createOrder?.data?.items;
					if (orderId) {
						chargingCustomer(
							cardSelected,
							data,
							orderId,
							items,
							anonymousUserId
						);
					} else {
						setAlert(t('Could not place order'), 'normal');
					}
					// if (isShowCardSection) {
					// 	onCompleteOrderWithCard({
					// 		chargeId: chargeId_,
					// 		items: data?.createOrder?.data?.items,
					// 		deliveryFee: deliveryFee,
					// 		amountToBePaid: amountToBePaid,
					// 	});
					// }
				},
			});
		}

		// DO NOT UNCOMMENT
		// chargingCustomer(cardSelected, data);
	};

	const [updateVoucher, { loading: payingWithVouchersLoading }] =
		useMutation(UPDATE_VOUCHER);

	// ----  PAYING WITH VOUCHER ONLY  ------
	const updatingPayingVouchers = async () => {
		const res = await isAnyItemInCartNotAvailable();
		if (res) {
			setAlert(
				t('Items') +
				' : ' +
				res.toString() +
				' ' +
				t('are no longer available'),
				'danger'
			);
		} else {
			await createOrder({
				variables: {
					args: {
						items,
						associatedUser: isUserLoggedIn
							? loggedInUserDetails?._id
							: null,
						associatedAnonymousUser: null,
						paidWith: 'VOUCHER',
						orderPaymentStatus: 'CHECKOUT',
						brands: brandInCartIds,
						eventType: 'LIVE',
						purchasedTotalAmount: Total,
						deliveryFee: deliveryFee,
						deliveryInfo: {
							name: payingAddressDeets?.name,
							phoneNumber: payingAddressDeets?.phoneNumber,
							emailAddress: isUserLoggedIn
								? loggedInUserDetails?.email
								: payingAddressDeets.email,
							deliveryAddress: {
								address: payingAddressDeets.address,
								city: payingAddressDeets.city,
								zipCode: payingAddressDeets.zipCode,
								country: getCountryLabelFromValue(
									payingAddressDeets.countery
								),
							},
						},
					},
				},
				onCompleted: data => {
					if (data?.createOrder?.success) {
						const orderId = data?.createOrder?.data?.orderId;
						let voucherUpdateInfo: TVoucherUpdateInfo[] = [];
						const amountSpent =
							+selectedVoucher.amount <= amountToBePaid
								? +selectedVoucher.amount
								: Total + deliveryFee;
						voucherUpdateInfo.push({
							_id: selectedVoucher.voucherId,
							amountLeft: selectedVoucherAmountLeft,
							amountSpent,
						});
						updateVoucher({
							variables: {
								args: {
									voucherUpdateInfo,
								},
							},
							refetchQueries: [{ query: GET_ALL_VOUCHERs }],
							onCompleted: async data => {
								if (data?.updateVoucher?.success) {
									await setPaymentStatus({
										variables: {
											orderId,
											paymentStatus: 'SUCCESS',
										},
									});
									await deleteCart();
									await setVoucherPaymentSuccessful(true);
								} else {
									await setPaymentStatus({
										variables: {
											orderId,
											paymentStatus: 'FAILED',
										},
									});
								}
							},
							onError: async () => {
								await setPaymentStatus({
									variables: {
										orderId,
										paymentStatus: 'FAILED',
									},
								});
							},
						});
					}
				},
			});
		}

		// let voucherUpdateInfo: TVoucherUpdateInfo[] = [];
		// voucherUpdateInfo.push({
		// 	_id: selectedVoucher.voucherId,
		// 	amountLeft: selectedVoucherAmountLeft,
		// });
		// // });
		// updateVoucher({
		// 	variables: {
		// 		args: {
		// 			voucherUpdateInfo,
		// 		},
		// 	},
		// 	refetchQueries: [{ query: GET_ALL_VOUCHERs }],
		// });
		// // order -status-pending
		// // stripe-remove- money
		// // if error
		// // status fails
		// // status success{
		// // 	send email
		// // }
		// await deleteCart();
		// await setVoucherPaymentSuccessful(true);
		// await createOrder({
		// 	variables: {
		// 		args: {
		// 			items,
		// 			paidWith: 'VOUCHER',
		// 			brand:
		// 				brandCount > 1
		// 					? 'Multiple'
		// 					: brandCount === 1 && brandInCartIds[0],
		// 			eventType: 'LIVE',
		// 			purchasedTotalAmount: Total,
		// 			deliveryFee: deliveryFee,
		// 			deliveryAddress: {
		// 				address: payingAddressDeets.address,
		// 				city: payingAddressDeets.city,
		// 				zipCode: payingAddressDeets.zipCode,
		// 				country: getCountryLabelFromValue(
		// 					payingAddressDeets.countery
		// 				),
		// 			},
		// 		},
		// 	},
		// });
	};

	const payingAddressDeets = useAppSelector(
		state => state.oneStopCheckout.address
	);
	const payingCardDeets = useAppSelector(state => state.oneStopCheckout.card);

	// ----  PAYING WITH CARD AND VOUCHER  ------

	const payingWithVoucherAndCard = async (data: any) => {
		payNowCard(cardSelected, data);
	};

	useEffect(() => {
		chargingData?.chargeCustomer?.success === false ||
			chargingWithoutCardData?.chargeCustomerWithoutSavingCard
				?.success === false ||
			confirmChargeData?.confirmCharge?.success === false;
	}, []);
	const navigation = useNavigation<TUseNavigation>();
	useEffect(() => {
		if (
			(chargingData?.chargeCustomer?.success === true &&
				chargingData?.chargeCustomer?.statusCode === 200) ||
			voucherPaymentSuccessful ||
			(chargingWithoutCardData?.chargeCustomerWithoutSavingCard
				?.success === true &&
				chargingWithoutCardData?.chargeCustomerWithoutSavingCard
					?.statusCode === 200) ||
			(confirmChargeData?.confirmCharge?.success === true &&
				confirmChargeData?.confirmCharge?.statusCode === 200) ||
			!isShowCardSection
		) {
			setAlert(t('Payment successful'), 'normal');
			navigation.navigate('PurchaseComplete');
		}
	}, [
		chargingData,
		voucherPaymentSuccessful,
		chargingWithoutCardData,
		confirmChargeData,
	]);

	useEffect(() => {
		if (CardsLoading) {
			dispatch(setloadingCards(true));
		}

		if (cardsListed) {
			dispatch(setloadingCards(false));
		}
	}, [cardsListed, CardsLoading]);

	const [getAppConfig_] = useLazyQuery(GET_APP_CONFIG);

	useEffect(() => {
		getAppConfig_({
			variables: {
				key: 'MAXIMUM_ELIGIBLE_FREE_SHIPPING_AMOUNT',
			},
			onCompleted: data => {
				setDeliveryFee(
					Total < +data?.getAppConfig?.data?.value?.value2
						? +data?.getAppConfig?.data?.value?.value1
						: 0
				);
				setMaxOrderForDeliveryFree(
					data?.getAppConfig?.data?.value?.value2
				);
			},
		});
	}, []);

	useEffect(() => {
		const selectedVoucherAmount = +selectedVoucher.amount;
		if (userAccountType === 'HOST' && selectedVoucherAmount) {
			const updatedAmountWithDelivery =
				Total + deliveryFee > +selectedVoucherAmount
					? Total + deliveryFee - (+selectedVoucherAmount || 0)
					: 0;
			const updatedAmountWithOutDelivery =
				Total > +selectedVoucherAmount
					? Total - (+selectedVoucherAmount || 0)
					: 0;

			if (Total < +maxOrderForDeliveryFee) {
				setAmountToBePaid(updatedAmountWithDelivery);
			} else {
				setAmountToBePaid(updatedAmountWithOutDelivery);
			}
		} else {
			if (Total < +maxOrderForDeliveryFee) {
				setAmountToBePaid(Total + deliveryFee);
			} else {
				setAmountToBePaid(Total);
			}
		}
	}, [Total, deliveryFee, maxOrderForDeliveryFee, selectedVoucher]);

	// 631b0c369d224a2222db22a2
	// 63402723b661612bd4bf660c
	const { getEvent } = useGetEvent({ id: eventBoughtFrom });
	useEffect(() => {
		eventBoughtFrom && getEvent();
	}, [eventBoughtFrom]);
	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			{CardsLoading || gettingVoucher ? (
				<Loader />
			) : (
				<>
					<View style={styles.container}>
						<View
							style={
								!isLessThanDesktopBase
									? styles.container_1
									: { width: '100%' }
							}
						>
							{userAccountType === 'HOST' && (
								<VoucherSection
									onCheckout
									brandsInCart={brandsInCart}
									amountToBePaid={amountToBePaid}
									selectedVoucher={selectedVoucher}
									chargeLoading={
										payingWithVouchersLoading ||
										createOrderLoading ||
										setPaymentStatusLoading ||
										deleteCartLoading ||
										gettingCartLoading
									}
									setSelectedVoucherAmountLeft={
										setSelectedVoucherAmountLeft
									}
									payWithVoucher={updatingPayingVouchers}
									total={Total}
									maxOrderForDelivery={
										+maxOrderForDeliveryFee
									}
									deliveryFee={deliveryFee}
								/>
							)}

							{userAccountType === 'HOST' &&
								+selectedVoucher.amount > 0 &&
								amountToBePaid > 0 && (
									<CardSection
										orderNow={data => {
											payingWithVoucherAndCard(data);
										}}
										isShowCardSection={isShowCardSection}
										onCheckout="yes"
										chargingLoading={
											chargingLoading ||
											chargingWithoutCardLoading ||
											createOrderLoading ||
											confirmChargeLoading ||
											loadingStripe3Ds ||
											gettingCartLoading
										}
										amountToBePaid={amountToBePaid}
										selectedCard={cardSelected}
										setSelectedCard={setCardSelected}
										total={Total}
										maxOrderForDelivery={
											+maxOrderForDeliveryFee
										}
										deliveryFee={deliveryFee}
									/>
								)}

							{userAccountType !== 'HOST' && (
								<CardSection
									orderNow={data => {
										if (isUserLoggedIn) {
											payNowCard(cardSelected, data);
										} else {
											handleCreateAnonymousUser(
												cardSelected,
												data
											);
										}
									}}
									isShowCardSection={isShowCardSection}
									onCheckout="yes"
									chargingLoading={
										chargingLoading ||
										chargingWithoutCardLoading ||
										createOrderLoading ||
										confirmChargeLoading ||
										loadingStripe3Ds ||
										gettingCartLoading
									}
									amountToBePaid={amountToBePaid}
									selectedCard={cardSelected}
									setSelectedCard={setCardSelected}
									total={Total}
									maxOrderForDelivery={
										+maxOrderForDeliveryFee
									}
									deliveryFee={deliveryFee}
								/>
							)}
						</View>
						{!isLessThanDesktopBase && (
							<View style={styles.container_2}>
								{usersCart.length > 0 && (
									<>
										<Text style={styles.headerText}>
											{/* {t('All Products')}(
											{usersCart.length < 10
												? `0${usersCart.length}`
												: usersCart.length}
											) */}
											{t('My cart')}
										</Text>
										<CartList onCheckout />
									</>
								)}
								<View
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
									}}
								>
									<View style={styles.breakDown}>
										<Text style={styles.subTotalText}>
											{t('Delivery Fee')}:
										</Text>
										<Text
											style={[
												styles.subtotal,
												{
													fontSize:
														getResponsiveStyle(11),
												},
											]}
										>
											{getSymbolFromCurrency('eur')}
											{Total > +maxOrderForDeliveryFee
												? 0
												: deliveryFee}
										</Text>
									</View>

									<View style={styles.total}>
										<Text style={styles.subTotalText}>
											{isLessThanDesktopBase
												? ''
												: t('Total')}
										</Text>
										<Text style={styles.subtotal}>
											{isLessThanDesktopBase
												? ''
												: `${getSymbolFromCurrency(
													'eur'
												)}${amountToBePaid.toFixed(
													2
												)}`}
										</Text>
									</View>
								</View>
							</View>
						)}
					</View>
				</>
			)}
		</DesktopTemplate>
	);
};

export default CheckoutScreen;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	total: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginTop: '15px',
		paddingTop: '15px',
		justifyContent: 'space-between',
		// marginLeft: '5%',
		borderTopColor: palettes.grey[5],
		height: 1,
		borderTopWidth: 1,
	},
	container_1: {
		width: '57%',
	},
	container_2: {
		borderLeftWidth: 1,
		borderLeftColor: 'whitesmoke',
		// marginRight: getResponsiveStyle(20),
		paddingLeft: getResponsiveStyle(17),
		width: '40%',
	},
	subtotal: {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		color: palettes.dark[2],
		fontWeight: 'bold',
	},
	subTotalText: {
		fontSize: getResponsiveStyle(11),
		lineHeight: getResponsiveStyle(18),
		color: palettes.grey[1],
	},
	breakDown: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(14, 'font'),
	},
	confirm: {
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		borderRadius: 5,
		marginTop: 10,
	},
});
