import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import {
	Image,
	Linking,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import useGetMedia from '../api/common/useGetMedia';
import { Button, Loader } from '../components';
import { EventImagePlaceholder } from '../components/common/event-card/EventImagePlaceholder';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import DescriptionContent from '../components/events/descriptionContent/DescriptionContent';
import DescriptionHeader from '../components/events/descriptionHeader/DescriptionHeader';
import DescriptionTabs from '../components/events/descriptionTabs/DescriptionTabs';
import DetailSidebar from '../components/events/detail-sidebar/DetailSidebar';
import LocationSection from '../components/events/location-section/LocationSection';
import PeopleScrollSection from '../components/events/people-scroll-section/PeopleScrollSection';
import ProductScrollSection from '../components/events/product-scroll-section/ProductScrollSection';
import { palettes } from '../config';
import { GET_SINGLE_EVENT } from '../graphql/events/queries';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import {
	setCurrentEvent,
	setIsAffiliatedHostEditingEvent,
	setIsEditEvent,
	setIsEditEventFromLink,
	setIsHostEditingEvent,
} from '../redux/getEventSlice';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useNavigation } from '@react-navigation/native';
import { setTypeOfEvent } from '../redux/createEventSlice';
import {
	setCreateEventSidebarValues,
	setCurrentScreen,
} from '../redux/uiSlice';
import { TUseNavigation } from '../types/exportedTypes';
import { useAppSelector } from '../redux/store';
import styled from 'rn-css';
import { IS_SUBSCRIBED_TO_EVENTS } from '../graphql/eventSubscription/queries';
import { isEventDone } from '../utils/statusOfEvent/isEventDone';
import { EventCardTag } from '../components/common/event-card/EventCardTag';
import { isEventUpcoming } from '../utils/statusOfEvent/isEventUpcoming';
import { isEevntOngoing } from '../utils/statusOfEvent/isEventOngoing';
import EventVideo from '../components/common/video-play/Video';
import { handleNavigationToEvent } from '../utils/handleNavigationToEvent';
import dayjs from 'dayjs';
import { Popable } from 'react-native-popable';
import { getStateOfEvent } from '../utils/handleNavigationToEvent/getStateOfEvent';
import LiveEventScreen from './LiveEventScreen';
import config from '../../config';

type Props = {
	route: TODO;
};

const StyledText = styled.Text`
	padding-bottom: 7;
	color: blue;
	margin-right: ${getResponsiveStyle(16)}px;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		border-bottom: 1px solid blue;
		color: blue;
	}
`;

function EventDescriptionScreen(props: Props) {
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();

	const SECTION_TABS = [
		{ label: t('Description'), value: 'Description' },
		{ label: t('Products'), value: 'Products' },
		{ label: t('Guest'), value: 'Guest' },
		{ label: t('Location'), value: 'Location' },
		// { label: t('Reviews'), value: 'Reviews' },
	];

	const SECTION: TEventDescriptionSection =
		'Description' as TEventDescriptionSection;

	const getTabs = (section: TEventDescriptionSection) => {
		return SECTION_TABS;
	};
	const [selectedTab, setSelectedTab] = React.useState<string>(
		getTabs(SECTION)[0].value
	);
	const [image, setImage] = useState<TMedia>();
	const { isLessThanDesktopBase, isDesktop } = useIsSpecificViewportWidth();
	const navigation = useNavigation<TUseNavigation>();
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));
	const dispatch = useDispatch();

	const [isSubscribed, setIsSubscribed] = useState(false);
	const [isLoadingProduct, setIsLoadingProduct] = useState(true);

	useEffect(() => {
		document
			.getElementById(selectedTab)
			?.scrollIntoView({ behavior: 'smooth' });
	}, [selectedTab]);

	// const { executeSingleEventQuery, loading, data } = useGetSingleEvent();

	const onPress = (tab: string) => {
		setSelectedTab(tab);
		// TODO: Set wondows scroll here
	};

	const eventId = props.route?.params?.eventId;

	const { data: eventData, loading: eventLoading } = useQuery(
		GET_SINGLE_EVENT,
		{
			fetchPolicy: 'no-cache',
			variables: {
				_id: eventId,
			},
		}
	);

	const event = eventData?.getEvent?.data;

	const stateOfEvent = getStateOfEvent(
		event?.eventStreamTimeline,
		event?.startingEventDateTime
	);

	useEffect(() => {
		dispatch(setCurrentScreen('/event-description'));
		if (
			['Upcoming', 'Ongoing']?.includes(stateOfEvent) &&
			event?.typeOfEvent === 'VIRTUAL'
		) {
			navigation.navigate('LiveEvent', {
				eventId,
			});
		}
	}, [event]);

	const { executeMediaQuery, data: mediaData } = useGetMedia();

	useEffect(() => {
		if (event?.eventMedia?.length) {
			executeMediaQuery({
				_id: event?.eventMedia[0],
			});
		}
	}, [eventData]);

	useEffect(() => {
		const image = mediaData?.getMedia?.data;
		if (image) setImage(image);
	}, [mediaData]);
	if (event) {
		dispatch(setCurrentEvent(event));
	}

	const handleEditEvent = (page: string) => {
		dispatch(setTypeOfEvent(event.typeOfEvent));
		if (event.typeOfEvent) {
			dispatch(setCreateEventSidebarValues(event.typeOfEvent));
			dispatch(setIsEditEventFromLink(true));
			dispatch(setIsEditEvent(true));
			dispatch(setIsAffiliatedHostEditingEvent(true));
			navigation.navigate(page as any);
		}
	};

	const isAffiliatedHost = () => {
		return (
			(loggedInUserDetails?._id === event?.affiliatedHosts?.[0]?._id &&
				event?.isHostApplicationAccepted) ||
			(!event?.affiliatedHosts?.length &&
				loggedInUserDetails?._id === event?.affiliatedSeller?._id &&
				event.typeOfEvent === 'VIRTUAL')
		);
	};

	const isAffiliatedSeller = () => {
		return loggedInUserDetails?._id === event?.affiliatedSeller?._id;
	};

	const isLocationAvailable = () => {
		return event?.eventLocation?.address1 || event?.eventLocation?.address2;
	};

	const isAllowedToSeeLocation = () => {
		if (loggedInUserDetails?.typeOfAccount === 'CONSUMER') {
			return event?.invitedPeople
				?.map((user: any) => user._id)
				?.includes(loggedInUserDetails?._id);
		} else if (isAffiliatedHost() || isAffiliatedSeller()) {
			return true;
		}
	};

	const aDayAfter = new Date(
		+event?.startingEventDateTime + 1000 * 60 * 60 * 24
	).getTime();

	const currentTime = new Date().getTime();

	const isEventEnded = () => {
		//if the event never started
		if (event?.typeOfEvent === 'VIRTUAL' && !event?.eventStreamTimeline) {
			return new Date().getTime() <
				new Date(+event?.startingEventDateTime).getTime()
				? false
				: true;
		}
		//if the event actual started
		return (
			(event?.typeOfEvent === 'VIRTUAL' &&
				event?.eventStreamTimeline?.endedAt) ||
			(event?.typeOfEvent === 'PHYSICAL' && currentTime > aDayAfter)
		);
	};

	const [isSubscribedToEvent] = useLazyQuery(IS_SUBSCRIBED_TO_EVENTS, {
		onCompleted: data =>
			setIsSubscribed(
				data?.isSubscribedToEvent?.data?.data[0]?.isSubscribed
			),
	});

	useEffect(() => {
		if (
			loggedInUserDetails?.typeOfAccount === 'HOST' ||
			loggedInUserDetails?.typeOfAccount === 'CONSUMER'
		) {
			isSubscribedToEvent({
				variables: {
					eventIds: [eventId],
				},
			});
		}
	}, [eventId]);

	const getClosedTag = () => {
		return <EventCardTag tagType="closed" />;
	};
	const getOngoingTag = () => {
		return <EventCardTag tagType="ongoing" />;
	};
	const getUpcomingTag = () => {
		return <EventCardTag tagType="upcoming" />;
	};

	const Tag = (): JSX.Element | null => {
		if (isEventDone(event)) {
			return getClosedTag();
		} else if (isEevntOngoing(event)) {
			return getOngoingTag();
		} else if (isEventUpcoming(event)) {
			return getUpcomingTag();
		} else {
			return null;
		}
	};

	const isShowViewProfileBtn =
		(isEventDone(event) || isEevntOngoing(event)) && !isUserLoggedIn;

	const getGoogleLink = () => {
		const formattedDate = dayjs(+event?.startingEventDateTime).format(
			'YYYYMMDDTHHmmss'
		);
		const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
			event?.name
		)}&dates=${encodeURIComponent(
			`${formattedDate}/${formattedDate}`
		)}&location=${encodeURIComponent(
			`${config.CLIENT_URL}/event/description?eventId=${event?._id}`
		)}`;
		Linking.openURL(googleCalendarUrl as string);
	};

	const getOutlookLink = () => {
		const formattedDate = dayjs(+event?.startingEventDateTime).format(
			'YYYYMMDDTHHmmss'
		);
		const outlookCalendarUrl = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&subject=${encodeURIComponent(
			event?.name
		)}&startdt=${encodeURIComponent(
			`${formattedDate}`
		)}&enddt=${encodeURIComponent(
			`${formattedDate}`
		)}&body=${encodeURIComponent(event?.description)}`;
		Linking.openURL(outlookCalendarUrl as string);
	};

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasLinks: false,
				hasSearch: false,
				hasGoBack: true,
				logoLocation: 'center',
			}}
		>
			{eventLoading && isLoadingProduct ? (
				<Loader fullView />
			) : event ? (
				Object.keys(event).length > 0 && (
					<ScrollView style={Styles.container}>
						<View style={{ marginBottom: getResponsiveStyle(21) }}>
							<DescriptionTabs
								onPress={onPress}
								selectedTab={selectedTab}
								tabs={getTabs(SECTION)}
								isAllowedLocation={
									isLocationAvailable() &&
									isAllowedToSeeLocation()
								}
							/>
						</View>
						{!!image && !!image?.src ? (
							<>
								{image.type === 'VIDEO' ? (
									<EventVideo
										data={image}
										containerStyles={{
											height: getResponsiveStyle(
												480,
												'dimensions'
											),
										}}
									/>
								) : (
									<Image
										source={{
											uri: image?.src,
										}}
										style={Styles.image}
									/>
								)}
							</>
						) : (
							<View
								style={{
									height: getResponsiveStyle(
										480,
										'dimensions'
									),
									borderRadius: 6,
									overflow: 'hidden',
								}}
							>
								<EventImagePlaceholder />
								<Tag />
							</View>
						)}

						<DescriptionHeader
							title={event.name}
							subtitle={t(event.typeOfEvent)}
							startDate={event.startingEventDateTime}
							endDate={event.endingEventDate}
							eventEnded={isEventEnded}
							eventId={eventId}
							isSubscribed={isSubscribed}
						/>
						{!isEventDone(event) && (
							<View style={{ justifyContent: 'space-between' }}>
								{/* <Text
									style={[
										Styles.freeShipping,
										isDesktop && { order: 2 },
									]}
								>
									{t(
										'Free delivery for purchases over 59€ in France. Safe and secure payment - 3D secure.'
									)}
								</Text> */}
								<View
									style={[
										isDesktop && {
											width: getResponsiveStyle(150),
											marginTop: getResponsiveStyle(10),
										},
									]}
								>
									<Popable
										content={
											<>
												<Pressable
													style={
														Styles.calenderOptions
													}
													onPress={() =>
														getGoogleLink()
													}
												>
													<Text>Google</Text>
												</Pressable>
												<Pressable
													style={
														Styles.calenderOptions
													}
													onPress={() =>
														getOutlookLink()
													}
												>
													<Text>Outlook</Text>
												</Pressable>
											</>
										}
									>
										<Text style={[Styles.addToCalender]}>
											{t('Add to calendar')}
										</Text>
									</Popable>
								</View>
							</View>
						)}
						<View style={[Styles.layout, styleContainer()]}>
							<View style={Styles.content}>
								<View nativeID="Description">
									<DescriptionContent
										content={event.description}
									/>
								</View>
								<View nativeID="Products">
									<ProductScrollSection
										eventDetails={event}
										setIsLoadingProduct={
											setIsLoadingProduct
										}
									/>
								</View>
								{loggedInUserDetails?.typeOfAccount ===
									'SELLER' && (
									<View nativeID="Guest">
										<PeopleScrollSection
											invitedPeople={event.invitedPeople}
											handleEditEvent={text =>
												handleEditEvent(text)
											}
											isShowInviteLink={
												isAffiliatedHost() &&
												!isEventEnded()
											}
											isShowViewProfileBtn={
												isShowViewProfileBtn
											}
										/>
									</View>
								)}

								{event.typeOfEvent === 'PHYSICAL' && (
									<View style={Styles.spacing}>
										{isLocationAvailable() &&
											isAllowedToSeeLocation() && (
												<>
													<View nativeID="Location">
														<LocationSection />
													</View>
													{isAffiliatedHost() &&
														!isEventEnded() && (
															<Pressable
																onPress={() => {
																	handleEditEvent(
																		'EventLocation'
																	);
																	dispatch(
																		setIsHostEditingEvent()
																	);
																}}
															>
																<StyledText>
																	{t(
																		'Change Address'
																	)}
																</StyledText>
															</Pressable>
														)}
												</>
											)}
									</View>
								)}

								{/* <DiscussionSection /> */}
							</View>
							{!isLessThanDesktopBase && (
								<>
									<View
										style={[
											Styles.layoutDivider,
											styleContainerDivider(),
										]}
									></View>
									<View
										style={[
											Styles.sidebar,
											styleContainerSidebar(),
										]}
									>
										<DetailSidebar />
									</View>
								</>
							)}
						</View>
					</ScrollView>
				)
			) : (
				<Text>{t('Event not found')}</Text>
			)}
		</DesktopTemplate>
	);
}

const Styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	image: {
		width: '100%',
		height: getResponsiveStyle(480),
		resizeMode: 'cover',
		borderRadius: 6,
	},
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		// marginTop: 40,
	},
	content: {
		flex: 1,
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},
	sidebar: {
		flexBasis: getResponsiveStyle(312),
		flexGrow: 0,
		flexShrink: 0,
		// flex: "0 0 312px"
	},
	editText: {
		fontSize: getResponsiveStyle(16, 'font'),
		fontWeight: '600',
		color: 'blue',
		textDecoration: 'underline',
	},
	spacing: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	freeShipping: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: 'normal',
		color: palettes.dark[2],
		marginTop: getResponsiveStyle(10),
	},
	addToCalender: {
		paddingTop: getResponsiveStyle(7),
		paddingBottom: getResponsiveStyle(7),
		paddingRight: getResponsiveStyle(5),
		paddingLeft: getResponsiveStyle(5),
		backgroundColor: palettes?.red[0],
		fontSize: getResponsiveStyle(14, 'font'),
		color: palettes?.light[0],
		borderRadius: 6,
		textAlign: 'center',
	},
	calenderOptions: {
		padding: 10,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
	},
});

export default EventDescriptionScreen;

function styleContainer() {
	return {
		marginTop: getResponsiveStyle(40),
		// gap: getResponsiveStyle(32),
	};
}
function styleContainerDivider() {
	return {
		marginLeft: getResponsiveStyle(40),
		// gap: getResponsiveStyle(32),
	};
}
function styleContainerSidebar() {
	return {
		paddingHorizontal: getResponsiveStyle(32),
		// gap: getResponsiveStyle(32),
	};
}
